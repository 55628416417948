import { Checkbox, FormControl, MenuItem, Select } from "@mui/material";
import React from "react";
import { ControlsContainer, StyledSelectTitle } from "../../Shared";


const TableControls = ({ gridRef, columns, toggleColumn, refetch }) => {
  return (
    <ControlsContainer sx={{ minHeight: "60px" }} className="example-header">
      <div>
        <StyledSelectTitle>Rows per page :</StyledSelectTitle>
        <Select
          sx={{ backgroundColor: "#ffffff", margin: "0 10px", height: "30px" }}
          defaultValue={100}
          id="page-size"
          label="Page Size"
          onChange={(event) => {
            const pageSize = event.target.value;
            gridRef.current.api.paginationSetPageSize(Number(pageSize));
          }}
        >
          <MenuItem value={100}>100</MenuItem>
          <MenuItem value={200}>200</MenuItem>
          <MenuItem value={500}>500</MenuItem>
          <MenuItem value={1000}>1000</MenuItem>
          <MenuItem value={10000}>10000</MenuItem>
        </Select>
      </div>

      <div>
        <StyledSelectTitle>Columns:</StyledSelectTitle>

        <FormControl
          sx={{ background: "#fff", margin: "0 10px" }}
          className="column-select"
        >
          <Select
            sx={{ width: "200px", height: "30px" }}
            multiple
            value={Object.keys(columns).filter((key) => columns[key])}
            onChange={(event, obj) => {
              const columnName = obj.props.value;
              toggleColumn(columnName);
            }}
            renderValue={(selected) => selected.join(", ")}
          >
            {Object.keys(columns).map((column) => (
              <MenuItem key={column} value={column}>
                <Checkbox checked={columns[column]} />
                {column}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </ControlsContainer>
  );
};

export default TableControls;
