import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import imgs from "../../imgs";
import { Image } from "@mui/icons-material";

export default function TermsAndConditionsDialog({
  open,
  handleClose,
  handleLogout,
}) {
  const scroll = "paper";

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const terms = [
    "You must keep your login credentials confidential and not share them with anyone. You are responsible for any activity that occurs under your account.",
    "You may download data from our Centralized Service Portal, but you may not share or distribute it.",
    "You may not copy, reproduce, modify, distribute, display, or create derivative works based on any of the Centralized Service Portals content, including the design, images, and content.",
    "You may not use our Centralized Service Portal for any illegal or unauthorized purpose, nor may you, in the use of the service, violate any laws in your jurisdiction (including but not limited to copyright laws).",
    "You acknowledge that PAR Innovations S.L. owns all intellectual property rights of the Centralized Service Portal, including any improvements or modifications to the service.",
    "PAR Innovations S.L. reserves the right to terminate your access to our the Centralized Service Portal at any time if you violate any of these terms and conditions.",
    "Accuracy, completeness, or reliability of any data or information displayed on Centralized Service Portal is not guaranteed, and PAR Innovations S.L. is not liable for any loss or damage resulting from your use of this service.",
    'The Centralized Service Portal is provided "as is" without warranty of any kind, either express or implied, including but not limited to the implied warranties of merchantability and fitness for a particular purpose.',
    "Terms and conditions may be updated at any time without notice. Your continued use of the Centralized Service Portal after any such changes constitutes your acceptance of the new terms and conditions.",
    "Any disputes arising out of, or related to these terms and conditions, shall be governed by and construed in accordance with the laws of the jurisdiction in which PAR Innovations S.L. operates.",
    "Any personal information that you provide to us through Centralized Service Portal will be handled in accordance with the regulation (EU) 2018/1725 of the European Parliament and of the Council of 23 October 2018.",
    "You agree to indemnify and hold harmless PAR Innovations S.L. and its affiliates, officers, agents, employees, and partners from any claims, liabilities, damages, or expenses (including reasonable attorneys' fees) arising out of, or related to, your use of the Centralized Service Portal.",
    "If any provision of these terms and conditions is deemed invalid or unenforceable, the remaining provisions will remain in full force and effect.",
    "These terms and conditions constitute the entire agreement between you and PAR Innovations S.L. regarding your use of our Centralized Service Portal and supersede all prior agreements and understandings, whether written or oral.",
  ];

  return (
    <div>
      <Dialog
        open={open}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          csp.par-innovations.com Terms and Conditions
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <div style={{width:'100%',display:'flex',alignItems:'center'}}>
            <img src={imgs.PARInnovationsBlock} alt={'par-logo'}/>
            </div>
            <p style={{ fontWeight: 600 }}>
              Welcome to the Centralized Service Portal website! <br />
              Before you start using our service, please read the following
              terms and conditions carefully.
              <br /> By using the Centralized Service Portal, you agree to be
              bound by these terms and conditions.
            </p>
            <ol>
              {terms.map((term) => (
                <li>{term}</li>
              ))}
            </ol>
            <p style={{ fontWeight: 600 }}>
              If you have any questions or concerns regarding these terms and
              conditions, please contact us at
              <br /><a href="mailto:Service@PAR-Innovations.com">Service@PAR-Innovations.com</a> 
              <br />
              Thank you for choosing PAR Innovations! We look forward to
              providing you with the best possible service.
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogout}>Cancel</Button>
          <Button onClick={handleClose}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
