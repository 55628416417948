export const setProject = (project) => {
  return (dispatch) => {
    dispatch({
      type: "setProject",
      payload: project,
    });
  };
};

export const clearProject = (project) => {
  return (dispatch) => {
    dispatch({
      type: "clearProject",
      payload: null,
    });
  };
};
