export const setPages = (pages) => {
  return (dispatch) => {
    dispatch({
      type: "setPages",
      payload: pages,
    });
  };
};

export const clearPages = (pages) => {
  return (dispatch) => {
    dispatch({
      type: "clearPages",
      payload: [],
    });
  };
};
