export const setProjects = (projects) => {
  return (dispatch) => {
    dispatch({
      type: "setProjects",
      payload: projects,
    });
  };
};

export const clearProjects = (projects) => {
  return (dispatch) => {
    dispatch({
      type: "clearProjects",
      payload: null,
    });
  };
};
