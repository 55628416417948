import styled from "@emotion/styled";
import { Breadcrumbs, Paper } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import TermsAndConditionsContent from "./TermsAndConditionsContent";

const TermsAndConditions = () => {
  const project = useSelector((state) => state.project);
  return (
    <>
      <StyledBreadcrumbs maxItems={3} aria-label="breadcrumb">
        <StyledLink
          last="true"
          underline="hover"
          color="ButtonText"
          to={`/TermsAndConditions`}
        >Terms and Conditions
        </StyledLink>
      </StyledBreadcrumbs>
      <StyledContentContainer>
       <TermsAndConditionsContent/>
      </StyledContentContainer>
    </>
  );
};
const StyledContentContainer = styled.div`
  height: 100%;
  display: flex;

  justify-content: center;
`;
const StyledProjectName = styled.span`
  font-size: 6vw;
  color: #508C9E;
  font-weight: 700;
`;

const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin: 10px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: rgba(0, 0, 0, 0.6);
  color: ${(props) => (props.last === "true" ? "black" : "rgba(0,0,0,0.6)")};
  &:hover {
    text-decoration: underline;
  }
`;
export default TermsAndConditions;
