import styled from "@emotion/styled";
import { Category, CheckCircle, ErrorRounded, Info } from "@mui/icons-material";
import { Button, CircularProgress, Paper, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Realtime from "../../../Shared/RealTime";
import configData from "../../../../config";
import { useDispatch, useSelector } from "react-redux";
import ErrorsModal from "../ErrorsModal";
import { tableDataActionsCreators } from "../../../../state";
import { bindActionCreators } from "redux";
import * as signalR from "@microsoft/signalr";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";

const StatusPaper = ({username}) => {
  const [data, setData] = useState(null);
  const [isErrorsModalOpen, setErrorsModalsOpen] = useState(false);
  const project = useSelector((state) => state.project);
  const [realTimeText, setRealTimeText] = useState("Connecting...");

  const connection = new signalR.HubConnectionBuilder()
    .withUrl(`${configData.SERVER_URL}Hubs/Status`, {
      skipNegotiation: true,
      transport: signalR.HttpTransportType.WebSockets,
    })
    .build();

  useEffect(() => {
    connection.on(`StatusChange/${project.name}`, (data) => {
      axios
        .get(`${configData.SERVER_URL}dashboard/status/${project.id}`)
        .then((res) => {
          setData(res.data.results);
          setTableData(res.data.results.flatMap((category) => category.errors));
        });
    });
    connection
      .start()
      

    return () => {
      connection.off("updateData");
      connection.stop();
    };
  }, []);

  const toggleErrorModal = () => {
    setErrorsModalsOpen((prev) => !prev);
  };

  const dispatch = useDispatch();
  const { setTableData } = bindActionCreators(
    tableDataActionsCreators,
    dispatch
  );
  useEffect(() => {
    axios.get(`${configData.SERVER_URL}dashboard/status/${project.id}`).then((res) => {
      setData(res.data.results);
      setTableData(res.data.results.flatMap((category) => category.errors));
    });
  }, []);

  const systemHasErrors = data
    ? data.some((Category) => Category.errors.length > 0)
    : false;
  const totalErrors = data?.reduce(
    (acc, section) => acc + section.errors.length,
    0
  );
const isArco = username.includes('ARCO')

  return (
    <StyledContainer>
      {data ? (
        <>
          {!systemHasErrors ? (
            <>
              <StyledLink style={{pointerEvents:isArco&&'none'}} to={`/${project.name.replace(/ /g, "-")}/Status`}>
                <CheckCircle sx={{ fontSize: "60px", color: "#508C9E" }} />
                <Typography sx={{ color: "#508C9E" }} variant="h4">
                  System is healthy
                </Typography>
              </StyledLink>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
              onClick={toggleErrorModal}
            >
              <ErrorRounded sx={{ fontSize: "60px", color: "#780000" }} />
              <Typography sx={{ color: "#780000" }} variant="h4">
                System has {totalErrors} errors
              </Typography>
            </div>
          )}
        </>
      ) :( <><span>getting system's status...</span><CircularProgress /></>)}
      {isErrorsModalOpen ? (
        <ErrorsModal
          handleClose={toggleErrorModal}
          open={isErrorsModalOpen}
          data={data}
        />
      ) : null}
    </StyledContainer>
  );
};

const StyledLink = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
`;

const StyledContainer = styled(Paper)`
  position: relative;
  padding: 30px;

  cursor: pointer;
  text-align: center;
  height: 100%;
  color: "#508C9E";
  box-shadow: 1px 8px 43px -2px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 1px 8px 43px -2px rgba(0, 0, 0, 0.5);
`;
export default StatusPaper;
