import Dialog from "@mui/material/Dialog";
import React from "react";

import DialogueBar from "../../Shared/DialogueBar";
import TableContainer from "./TableContainer.jsx";

const ErrorsModal = ({ open, handleClose, scannerId }) => {
  return (
    <Dialog color="#F1F1FC" fullScreen open={open} onClose={handleClose}>
      <DialogueBar handleClose={handleClose} title={`SYSTEM ERRORS`} />
      <TableContainer />
    </Dialog>
  );
};

export default ErrorsModal;
