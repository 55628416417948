import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import HistoryPaper from './HistoryPaper';
import PerformancePaper from './PerformancePaper';
import StatusPaper from './StatusPaper';
import _ from 'lodash';
import RGL,{ WidthProvider } from 'react-grid-layout';
import { Card } from '@mui/material';

const NewDashboardContent = ({username}) => {
  const HistoryPaperMemoized = React.memo(HistoryPaper);
  const PerformancePaperMemoized = React.memo(PerformancePaper);
  const StatusPaperMemoized = React.memo(StatusPaper);

  const ReactGridLayout = WidthProvider(RGL);
  const project = useSelector((state) => state.project);
  function saveToLS(key, value) {
    if (localStorage) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }

  const defaultLayout= [
    {
      x: 2,
      y: 1,
      w: 2,
      minW: 1,
      maxW: 2,
      minH: 1,
      maxH: 20,
      h: 8,
      i: "history",
      isResizable: true,
      isDraggable:true
    },
    {
      x: 0,
      y: 1,
      w: 2,
      minW: 1,
      maxW: 2,
      minH: 1,
      maxH: 20,
      h: 4,
      i: "operational",
      isResizable: true,
    },
    {
      x: 0,
      y: 2,
      w: 2,
      minW: 1,
      maxW: 2,
      minH: 1,
      maxH: 20,
      h: 4,
      i: "status",
      isResizable: true,
    },];

  const [layout, setLayout] = useState(() => {
    const storedLayout = getFromLS("layout");

    // Check if storedLayout has valid layout data, otherwise use defaultLayout
    return _.isArray(storedLayout) && !_.isEmpty(storedLayout)
      ? storedLayout
      : defaultLayout;
  });
  // useEffect(() => {
  //   // saveToLS("layout", layout); // Save layout to localStorage whenever it changes
  // }, [layout]);

  const resetLayout = () => {
    setLayout(defaultLayout);
  };
  const handleLayoutChange = (newLayout) => {
    // Do something with newLayout if needed
    setLayout(newLayout);
  };

  function getFromLS(key) {
    let ls = {};
    if (localStorage) {
      try {
        const item = localStorage.getItem(key);

        if (item !== null) {
          ls = JSON.parse(item);
        }
      } catch (e) {
        /* Ignore */
      }
    }
    return ls;
  }
console.log("test")
  return (
    <>
      <ReactGridLayout
        layout={layout}
        onLayoutChange={handleLayoutChange}
        className="layout"
        style={{overflowY:"auto", overflowX:"hidden", minHeight:"100%",minWidth:"100%"}}
        rowHeight={100}
        cols={4}
        isResizable={true}
        
      >
              <Card
          key={"operational"}
        >
      <PerformancePaperMemoized  project={project}/>
        </Card>
        <Card
          key={"status"}
        >

      <StatusPaperMemoized  username={username}/>
        </Card>
        <Card
        sx={{overflowY:'auto'}}
         key={"history"}
        >
      <HistoryPaperMemoized  project={project}/>
        </Card>
      </ReactGridLayout>
    </>
  )
}
export default NewDashboardContent;