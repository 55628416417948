import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ margin: "auto" }}
      width="50"
      height="50"
      display="block"
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 100 100"
    >
      <circle cx="84" cy="50" r="10" fill="#006d79">
        <animate
          attributeName="r"
          begin="0s"
          calcMode="spline"
          dur="1.1363636363636365s"
          keySplines="0 0.5 0.5 1"
          keyTimes="0;1"
          repeatCount="indefinite"
          values="11;0"
        ></animate>
        <animate
          attributeName="fill"
          begin="0s"
          calcMode="discrete"
          dur="4.545454545454546s"
          keyTimes="0;0.25;0.5;0.75;1"
          repeatCount="indefinite"
          values="#006d79;#00aac1;#006d79;#00aac1;#006d79"
        ></animate>
      </circle>
      <circle cx="16" cy="50" r="10" fill="#006d79">
        <animate
          attributeName="r"
          begin="0s"
          calcMode="spline"
          dur="4.545454545454546s"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          keyTimes="0;0.25;0.5;0.75;1"
          repeatCount="indefinite"
          values="0;0;11;11;11"
        ></animate>
        <animate
          attributeName="cx"
          begin="0s"
          calcMode="spline"
          dur="4.545454545454546s"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          keyTimes="0;0.25;0.5;0.75;1"
          repeatCount="indefinite"
          values="16;16;16;50;84"
        ></animate>
      </circle>
      <circle cx="50" cy="50" r="10" fill="#00aac1">
        <animate
          attributeName="r"
          begin="-1.1363636363636365s"
          calcMode="spline"
          dur="4.545454545454546s"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          keyTimes="0;0.25;0.5;0.75;1"
          repeatCount="indefinite"
          values="0;0;11;11;11"
        ></animate>
        <animate
          attributeName="cx"
          begin="-1.1363636363636365s"
          calcMode="spline"
          dur="4.545454545454546s"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          keyTimes="0;0.25;0.5;0.75;1"
          repeatCount="indefinite"
          values="16;16;16;50;84"
        ></animate>
      </circle>
      <circle cx="84" cy="50" r="10" fill="#006d79">
        <animate
          attributeName="r"
          begin="-2.272727272727273s"
          calcMode="spline"
          dur="4.545454545454546s"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          keyTimes="0;0.25;0.5;0.75;1"
          repeatCount="indefinite"
          values="0;0;11;11;11"
        ></animate>
        <animate
          attributeName="cx"
          begin="-2.272727272727273s"
          calcMode="spline"
          dur="4.545454545454546s"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          keyTimes="0;0.25;0.5;0.75;1"
          repeatCount="indefinite"
          values="16;16;16;50;84"
        ></animate>
      </circle>
      <circle cx="16" cy="50" r="10" fill="#00aac1">
        <animate
          attributeName="r"
          begin="-3.409090909090909s"
          calcMode="spline"
          dur="4.545454545454546s"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          keyTimes="0;0.25;0.5;0.75;1"
          repeatCount="indefinite"
          values="0;0;11;11;11"
        ></animate>
        <animate
          attributeName="cx"
          begin="-3.409090909090909s"
          calcMode="spline"
          dur="4.545454545454546s"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          keyTimes="0;0.25;0.5;0.75;1"
          repeatCount="indefinite"
          values="16;16;16;50;84"
        ></animate>
      </circle>
    </svg>
  );
}

export default Icon;
