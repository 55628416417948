import Dialog from "@mui/material/Dialog";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import configData from "../../../config.js";
import { useSelector } from "react-redux";
import DialogueBar from "../../Shared/DialogueBar";
import moment, { now } from "moment";
import { useGetData } from "../../../hooks/hooks.jsx";
import TableControls from "../../Shared/TableControls.jsx";
import Table from "../../Shared/Table.jsx";
import { Box } from "@mui/material";
import {
  Bar,
  BarChart,
  Legend,
  Tooltip,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import toast from "react-hot-toast";

const ScannerDaily = ({ open, handleClose, scannerId,renderColor }) => {
  const gridRef = useRef();
  const project = useSelector((state) => state.project);

  const [linesRendered, setLinesRendered] = useState(false);
  useLayoutEffect(() => {
    if (linesRendered) {
      toast.success("ready", { id: "chart" });
    }
  }, [linesRendered]);

  const handleLineRender = () => {
    setLinesRendered(true);
  };



  const [columns, setColumns] = useState({
    date: true,
    success: true,
    noRead: true,
    noScan: true,
    total: true,
  });

  const toggleColumn = useCallback((column) => {
    setColumns((prev) => ({
      ...prev,
      [column]: !prev[column],
    }));
  }, []);

  const columnDefs = [
    {
      field: "date",
    },
    {
      field: "success",
      suppressSizeToFit: true,
      hide: !columns.success,
      valueGetter: (params) => {
        const percentageNumber =
          (params.data.success / params.data.total) * 100;
        return !params.data.total ? "-" : `${percentageNumber.toFixed(2)}%`;
      },
      cellRenderer: ({ value }) => {
        return <span style={{ color: renderColor(value) }}>{value}</span>;
      },
    },
    {
      field: "noRead",
      hide: !columns.noRead,
      suppressSizeToFit: true,
      valueGetter: (params) => {
        const percentageNumber = (params.data.noRead / params.data.total) * 100;
        return !params.data.total ? "-" : `${percentageNumber.toFixed(2)}%`;
      },
      cellRenderer: ({ value, data }) => {
        return (
          <span
            style={{ color: renderColor((data.success / data.total) * 100) }}
          >
            {value}
          </span>
        );
      },
    },
    {
      field: "total",
      hide: !columns.total,
    },
  ];
  const [auxId, setAuxId] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const endpoint = `${configData.SERVER_URL}scans/summary/daily/${project.id}`;

  const {
    data,
    refetch,
    remove,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isRefetching,
  } = useGetData({
    endpoint,
    parameters: { auxId, scannerId, pageSize: 100000, selectedDate },
    requestId: `${project.id}-scanners-${scannerId}-daily`,
  });

  const handleModifyAuxId = (value) => {
    setAuxId(value);
  };

  const handleDateChange = (_, date) => {
    remove();
    handleModifyAuxId(null);
    if (date === null) {
      setSelectedDate(moment(now()).format("YYYY-MM-DD Z"));
    } else {
      setSelectedDate(moment(date).format("YYYY-MM-DD Z"));
    }
  };

  useEffect(() => {
    if (data) {
      handleModifyAuxId(data?.pages[0]?.data?.auxId);
    }
  }, [data]);

  const totalServerPages = data?.pages[0]
    ? data?.pages[0]?.data?.pageCount
    : null;

  const totalRows = data?.pages[0] ? data?.pages[0]?.data?.rowsCount : null;
  const allResults = data
    ? data?.pages?.flatMap((page) => page?.data.results)
    : null;
  const { firstDate } = useSelector((state) => state.project);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const total = payload[0].value + payload[1].value
      const success = payload[0].value 
      const noRead = payload[1].value
      return (
        <div style={{background:"white", padding:"5px" ,border:"1px black solid"}} className="custom-tooltip">
          <strong className="label">{`${moment(label).format('dddd')} ${label} `}</strong>
          <p className="label" style={{color:"#5F9E77"}}>Success: {success + (total===0?"":` (${((success/total) * 100).toFixed(2)}%)`)}</p>
          <p className="intro" style={{color:"#9b2226"}}>No Reads: {noRead + (total===0?"":` (${((noRead/total) * 100).toFixed(2)}%)`)}</p>
          <p className="intro">Total: {total}</p>
        </div>
      );
    }
  
    return null;
  };
  return (
    <Dialog color="#F1F1FC" fullScreen open={open} onClose={handleClose}>
      <DialogueBar handleClose={handleClose} title={`${scannerId} DAILY`} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <TableControls
          gridRef={gridRef}
          columns={columns}
          toggleColumn={toggleColumn}
          refetch={refetch}
          remove={remove}
          handleDateChange={handleDateChange}
          hasDateFilter={false}
          handleModifyAuxId={handleModifyAuxId}
          data={data}
        />
        <Box sx={{ height: "30vh", width: "100vw"}}>
         {data && <ResponsiveContainer>
            <BarChart data={data?.pages[0].data.results}>
              <XAxis reversed type="category" dataKey="date" />
              <YAxis />
              <Tooltip content={<CustomTooltip />}/>
              <Legend />
              <Bar barSize={40} dataKey="success" stackId="a" fill="#82ca9d" />
              <Bar barSize={40} dataKey="noRead" stackId="a" fill="#9b2226" />
            </BarChart>
          </ResponsiveContainer>}
        </Box>
        <Table
          data={allResults}
          gridRef={gridRef}
          columnDefs={columnDefs}
          fetchNextPage={fetchNextPage}
          totalServerPages={totalServerPages}
          pageSize={100000}
          totalRows={totalRows}
          hasNextPage={hasNextPage}
          isRefetching={isRefetching}
          isLoading={isLoading}
          hasFirstDateColumn={false}
          firstDate={firstDate}
        />
      </div>
    </Dialog>
  );
};

export default ScannerDaily;
