import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { bindActionCreators } from "redux";
import {
  pagesActionsCreators,
  projectsActionsCreators,
} from "../state";
import { useDispatch } from "react-redux";
import moment from "moment";

export function useGetData({
  endpoint,
  requestId,
  parameters,
  isTableData = true,
  refetchInterval = false,
  currentPage = 1,
}) {
  const fetchInfiniteData = async ({ pageParam = 1 }) => {
    try {
      if (sessionStorage.getItem("accessToken")) {
        return await axios.get(endpoint, {
          params: { ...parameters, currentPage: pageParam },
        });
      }
    } catch (e) {
      throw new Error(e);
    }
  };
  return useInfiniteQuery([requestId, currentPage], fetchInfiniteData, {
    getNextPageParam: (lastPage, pages) => {
      if (lastPage?.data.currentPage !== lastPage?.data.pageCount) {
        return pages.length + 1;
      } else {
        return false;
      }
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchInterval,
  });
}
export function useGetDashboardPerformance({
  endpoint,
  requestId,
  parameters,
  isTableData = true,
  refetchInterval = false,

  currentPage = 1,
}) {
  const fetchInfiniteData = async ({ pageParam = 1 }) => {
    try {
      if (sessionStorage.getItem("accessToken")) {
        const res = await axios.get(endpoint, {
          params: { ...parameters, currentPage: pageParam },
        });
        return {
          ...res,
          data: { ...res.data, results: res.data.results.reverse() },
        };
      }
    } catch (e) {
      throw new Error(e);
    }
  };
  return useInfiniteQuery([requestId, currentPage], fetchInfiniteData, {
    getNextPageParam: (lastPage, pages) => {
      if (lastPage?.data.currentPage !== lastPage?.data.pageCount) {
        return pages.length + 1;
      } else {
        return false;
      }
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchInterval,
  });
}



export function useGetPages({ endpoint, requestId }) {
  const dispatch = useDispatch();
  const { setPages } = bindActionCreators(pagesActionsCreators, dispatch);
  return useQuery({
    queryKey: [requestId],
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,

    queryFn: async () => {
      if (sessionStorage.getItem("accessToken")) {
        const { data } = await axios.get(endpoint, {
          params: { pageSize: 100 },
        });
        setPages(data.results);
        return data.results;
      }
    },
  });
}
export function useGetMetrics({
  endpoint,
  requestId,
  refetchInterval,
  parameters,
}) {
  return useQuery({
    queryKey: [requestId],
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchInterval,
    cacheTime: 0,

    queryFn: async () => {
      if (sessionStorage.getItem("accessToken")) {
        const { data } = await axios.get(endpoint, { params: parameters });
        return data.results.map((obj) => ({
          ...obj,
          metrics: obj.metrics,
        }));
      }
    },
  });
}

export function useGetProjects({ endpoint, requestId }) {
  const dispatch = useDispatch();
  const { setProjects } = bindActionCreators(projectsActionsCreators, dispatch);
  return useQuery({
    queryKey: [requestId],
    queryFn: async () => {
      if (sessionStorage.getItem("accessToken")) {
        const { data } = await axios.get(endpoint);
        setProjects(data.results);

        return data.results;
      }
    },
  });
}

export function useGetLayouts({ endpoint, requestId }) {
  return useQuery({
    queryKey: [requestId],
    refetchOnMount: false,
    cacheTime:0,
    refetchInterval:0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,

    queryFn: async () => {
      const { data } = await axios.get(endpoint);
      if (data.results.length > 0) {
        return data.results;
      } else {
        return [];
      }
    },
  });
}

export function useGetOperational({
  endpoint,
  requestId,
  parameters,
  refetchInterval = false,
}) {
  return useQuery({
    queryKey: [requestId],
    refetchInterval,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    cacheTime: 0,
    queryFn: async () => {
      const { data } = await axios.get(endpoint, {
        params: { ...parameters, pageSize: 100 },
      });

      return data.results.map((object) => {
        let total = 0;
        return {
          ...object,
          occurrences: object.occurrences.reverse().map((value) => {
            total += value.amount;
            return {
              ...value,
              dateFrom: moment(value.dateFrom).format("DD/MM/YYYY HH:mm"),
              dateTo: moment(value.dateTo).format("DD/MM/YYYY HH:mm"),
              dateRange: `${moment(value.dateFrom).format(
                "DD/MM/YYYY HH:mm"
              )} - ${moment(value.dateTo).format("HH:mm")}`,
              total: total,
            };
          }),
        };
      });
    },
  });
}
