import styled from "@emotion/styled";
import React, { useRef, useState } from "react";
// import imgs from "../../imgs";
import Lottie from "lottie-react";
import animationData from "./presets/dashboard-animation.json";
import { Button, TextField, Typography } from "@mui/material";
import {
  ArrowRightAltOutlined,
  LockOutlined,
  PersonOutlined,
} from "@mui/icons-material";
import { toast } from "react-hot-toast";
import axios from "axios";
import configData from "../../config.js";

const NewLogin = () => {
  const animation = useRef();
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [isPasswordError, setIsPasswordError] = useState(false);
  const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!username || !password) {
      toast.error("Please enter both username and password");
      return;
    }

    if (!passwordRegex.test(password)) {
      setIsPasswordError(true);
      toast.error("Incorrect password format");
      return;
    }

    setLoading(true);

    axios
      .post(
        `${configData.SERVER_URL}Auth/Login`,
        {},
        {
          auth: {
            username,
            password,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        sessionStorage.setItem("accessToken", res.data.access_token);
        sessionStorage.setItem("refreshToken", res.data.refresh_token);
        toast.success("Login successful!");
        window.location.reload();
        return res;
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };
  const handlePasswordChange = (e) => {
    if (isPasswordError) {
      setIsPasswordError(false);
    }
    setPassword(e.target.value);
  };
  return (
    <StyledLoginSpan>
      <StyledHeader>
        {/* <StyledImage src={imgs.PARInnovationsBlock} alt="par logo" /> */}
      </StyledHeader>
      <StyledPageContent>
        <StyledLeftContent>
          <Lottie
            rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
            lottieRef={animation}
            animationData={animationData}
          />
        </StyledLeftContent>
        <StyledFormContent>
          <div className="">
            <Typography variant="h3" fontWeight={600}>
              Welcome Back!
            </Typography>
            <Typography variant="subtitle2" fontWeight={600}>
              Enter your credentials to login
            </Typography>
          </div>
          <StyledForm onSubmit={handleSubmit}>
            <InputContainer>
              <PersonOutlined />
              <StyledInput
              autoFocus
                placeholder="Username"
                onChange={handleUsernameChange}
                value={username}
              />
            </InputContainer>
            <InputContainer>
              <LockOutlined />
              <StyledInput
                error={isPasswordError}
                onChange={handlePasswordChange}
                type="password"
                placeholder="password"
                value={password}
              />
            </InputContainer>
            <ButtonContainer>
              <Button
                        sx={{background:'#508C9E'}}
                disabled={!password || !username || loading || isPasswordError}
                type="submit"
                size="large"
                variant="contained"
              >
                Login
                <ArrowRightAltOutlined />
              </Button>
            </ButtonContainer>
          </StyledForm>
        </StyledFormContent>
      </StyledPageContent>
    </StyledLoginSpan>
  );
};
const StyledLoginSpan = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;
// const StyledImage = styled.img`
//   width: 200px;
// `;
const StyledHeader = styled.header`
  padding: 40px;
`;
const StyledPageContent = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  position: relative;
  overflow: hidden;
  @media screen and (max-width: 570px) {
    justify-content: center;
  }
`;
const StyledLeftContent = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 570px) {
    display: none;
  }
`;
const StyledFormContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
`;
const InputContainer = styled.div`
  display: flex;
  gap: 5;
  align-items: center;
  margin: 30px 0px;
  width: 100%;
  position: relative;
`;
const ButtonContainer = styled.div`
  display: flex;
  gap: 5;
  align-items: center;
  margin: 30px;
  width: 100%;
  position: relative;
`;
const StyledInput = styled(TextField)`
  height: 40px;
  width: 60%;
  margin: 5px;
  @media screen and (max-width: 768px) {
    width: 70%;
  }

  @media screen and (max-width: 570px) {
    width: 80%;
  }
`;

const StyledForm = styled.form``;
export default NewLogin;
