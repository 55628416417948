import { AgGridReact } from "ag-grid-react";
import React, { useMemo } from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { useSelector } from "react-redux";
import { LightTooltip } from "../../Shared";

const Table = ({ gridRef, columnDefs }) => {
  const data = useSelector((state) => state.tableData);
  const defaultColDef = useMemo(() => {
    return {
      minWidth: 50,
      floatingFilter: true,
      resizable: true,
      filter: true,
      editable: true,
      flex: 1,
      cellRenderer: (data) => {
        return (
          <LightTooltip title={`${data.value}`}>
            <span>{`${data.value}`}</span>
          </LightTooltip>
        );
      },
    };
  }, []);

  return (
    <div className="grid-wrapper ag-theme-alpine" style={{ flex: 1 }}>
      <AgGridReact
        ref={gridRef}
        rowData={data}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        pagination={true}
        rowHeight={50}
        suppressDragLeaveHidesColumns={true}
        overlayLoadingTemplate={
          '<span class="ag-overlay-loading-center">Please wait while requesting data</span>'
        }
        overlayNoRowsTemplate="no data Available"
      ></AgGridReact>
    </div>
  );
};

export default Table;
