import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import configData from "../../../config.js";
import { tableDataActionsCreators } from "../../../state/index.js";

import TableWithControls from "../../Shared/TableWithControls.jsx";
import { LightTooltip } from "../../Shared/index.js";
import moment from "moment";

const StatusContent = () => {
  const gridRef = useRef();
  const project = useSelector((state) => state.project);

  const dispatch = useDispatch();
  const { setTableData } = bindActionCreators(
    tableDataActionsCreators,
    dispatch
  );
  useEffect(() => {
    return () => {
      setTableData([]);
    };
  }, []);

  const [columns, setColumns] = useState({
    objectId: true,
    category: true,
    subCategory: true,
    property: true,
    value: true,
    createdAt: true,
  });

  const toggleColumn = useCallback((column) => {
    setColumns((prevColumns) => ({
      ...prevColumns,
      [column]: !prevColumns[column],
    }));
  }, []);

  const columnDefs = useMemo(
    () => [
      {
        field: "objectId",
        suppressSizeToFit: true,
        hide: !columns.objectId,
      },
      {
        field: "category",
        hide: !columns.category,
        suppressSizeToFit: true,
      },
      {
        field: "subCategory",
        suppressSizeToFit: true,
        hide: !columns.subCategory,
      },
      {
        field: "property",
        suppressSizeToFit: true,
        hide: !columns.property,
      },
      {
        hide: !columns.value,
        field: "value",
      },
      {
        field: "createdAt",
        suppressSizeToFit: true,
        hide: !columns.createdAt,
        flex: 4,
        valueGetter: ({data})=>{
          return moment(data.createdAt).format("YYYY/MM/DD HH:mm:ss.SSS")
        },
      },
    ],
    [columns]
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <TableWithControls
        toggleColumn={toggleColumn}
        gridRef={gridRef}
        columns={columns}
        columnDefs={columnDefs}
        endpoint={`${configData.SERVER_URL}Statuses/${project.id}`}
        requestId={`${project.id}-status`}
      />
    </div>
  );
};

export default StatusContent;
