import React, { Suspense, lazy, useState } from "react";
import configData from "../../../config.js";

import { useSelector } from "react-redux";
import { Box,  Typography, Switch, Paper } from "@mui/material";
import {  useGetOperational } from "../../../hooks/hooks.jsx";
import styled from "@emotion/styled";
import { DatePicker } from "antd";
import moment, { now } from "moment";
const Chart = lazy(() => import("./Chart.jsx"));

const OperationalContent = () => {
  const project = useSelector((state) => state.project);
  // const [data, setData] = useState(null);
  const [realtime, setRealtime] = useState(false);
const [selectedDate,setSelectedDate] = useState(moment(now()).format("YYYY-MM-DD Z"))
  const { data,refetch,remove } = useGetOperational({
    endpoint: `${configData.SERVER_URL}operational/${project.id}`,
    refetchInterval: realtime ? 15000 : false,
    requestId: `${project.id}-operational`,
    parameters: {selectedDate},
  });
  const handleRealtimeToggle = () => {
    if(!realtime){
      refetch()
    }
    setRealtime(!realtime);
  };

  const maxTotalObject =(data && data.length>0)? data.reduce((maxObj, currentObj) => {
    return currentObj.totalOccurrences > maxObj.totalOccurrences ? currentObj : maxObj;
  }, data[0]):null; 
const maxTotal = (data && data.length>0)?maxTotalObject.occurrences.at(-1).total:null;
const {firstDate} = useSelector((state) => state.project);
const disabledDate = (current) => {
  
  return current && (current > moment().endOf("day") || current < moment(firstDate));
};


const handleDateChange = (_,date) => {
  remove();
  if (date === null) {
    setSelectedDate(moment(now()).format("YYYY-MM-DD Z"));
  } else {
    setSelectedDate(moment(date).format("YYYY-MM-DD Z"));
  }
};
  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent:"space-between", marginBottom: "10px" }}>
        <div style={{ display: "flex", alignItems: "center"}}>
        <Switch
          disabled={!data || data.length < 1}
          checked={realtime}
          onChange={handleRealtimeToggle}
          />
        <Typography sx={{ marginLeft: "10px" }}>Automatic updates</Typography>
          </div>
          <DatePicker
     disabled={!data || realtime} 
      onChange={handleDateChange}
      allowClear={false}
      disabledDate={disabledDate}
      />
      <div></div>

      </Box>
      <Box
        sx={{
          height: "100%",
          overflow: "auto",
          display: "flex",
          flexWrap: "wrap",
          padding: "2%",
          justifyContent: "center",
        }}
      >
        {data && data.length > 0 ? (
          data.map((chart) =>  <Suspense
          key={chart.location}
          fallback={
            <ChartContainer
              style={{
                position: "relative",
                width: "670px",
                height: "450px",
              }}
            >
              <strong>{chart.objectId}</strong>
              <span>{chart.property}</span>
              Loading...
            </ChartContainer>
          }
        >
          <Chart data={chart} max={maxTotal} />
          </Suspense>)
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >

            <Typography sx={{ marginX: "5px" }}>{data?.length===0 ? 'No data': <Loader class="ag-overlay-loading-center">Please wait while requesting data</Loader>}</Typography>
          </Box>
        )}
      </Box>
    </>
  );
};
const ChartContainer = styled(Paper)`
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Loader = styled.span`
background-color:#F1F1F1;
color:#3E4244;
padding:6px;
border: solid 1px #3E4244;
font-size:14px;
box-shadow: 10px -1px 75px 10px rgba(0,0,0,0.27);
-webkit-box-shadow: 10px -1px 75px 10px rgba(0,0,0,0.27);
-moz-box-shadow: 10px -1px 75px 10px rgba(0,0,0,0.27);
`
export default OperationalContent;
