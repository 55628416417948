import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./state/store";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { createAsyncStoragePersister } from "@tanstack/query-async-storage-persister";
import { lightTheme } from "./themes";
import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import "react-grid-layout/css/styles.css"
import "react-resizable/css/styles.css"

const root = ReactDOM.createRoot(document.getElementById("root"));
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0, // 24 hours
    },
  },
});
const asyncStoragePersister = createAsyncStoragePersister({
  storage: window.localStorage,
});
root.render(
  <Provider store={store}>
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister: asyncStoragePersister}}
      
    >
      <BrowserRouter>
        {/* <ReactQueryDevtools initialIsOpen /> */}
        <ThemeProvider theme={lightTheme}>
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </PersistQueryClientProvider>
  </Provider>
);
