import Dialog from "@mui/material/Dialog";
import React, { useCallback, useRef, useState } from "react";
import configData from "../../../config.js";
import { useSelector } from "react-redux";
import TableWithControls from "../../Shared/TableWithControls";
import DialogueBar from "../../Shared/DialogueBar";
import { LightTooltip } from "../../Shared/index.js";
import moment from "moment";

const ScannerHistory = ({ open, handleClose, scannerId }) => {
  const gridRef = useRef();
  const project = useSelector((state) => state.project);

  const [columns, setColumns] = useState({
    createdAt: true,
    barcodes: true,
    result: true,
  });

  const toggleColumn = useCallback((column) => {
    setColumns((prev) => ({
      ...prev,
      [column]: !prev[column],
    }));
  }, []);

  const columnDefs = [
    {
      field: "createdAt",

      hide: !columns.createdAt,
      valueGetter: ({data})=>{
        return moment(data.createdAt).format("YYYY/MM/DD HH:mm:ss.SSS")
      },
    },
    {
      field: "values",
      hide: !columns.barcodes,
    },
    { field: "result", hide: !columns.result },
  ];

  return (
    <Dialog color="#F1F1FC" sx={{zIndex:1}} fullScreen open={open} onClose={handleClose}>
      <DialogueBar handleClose={handleClose}  title={`${scannerId} HISTORY`} />
      <TableWithControls
        toggleColumn={toggleColumn}
        gridRef={gridRef}
        hasDateFilter
        columns={columns}
        columnDefs={columnDefs}
        endpoint={`${configData.SERVER_URL}Scans/${project.id}`}
        parameters={{ scannerId }}
        requestId={`${project.id}-scanners-${scannerId}-history`}
      />
    </Dialog>
  );
};

export default ScannerHistory;
