import styled from "@emotion/styled";
import { Breadcrumbs } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import StatusContent from "./StatusContent.jsx";

const Status = () => {
  const project = useSelector((state) => state.project);

  return (
    <>
      <StyledBreadcrumbs maxItems={3} aria-label="breadcrumb">
        <StyledLink underline="hover" color="inherit" to="/">
          Projects
        </StyledLink>
        <StyledLink
          underline="hover"
          color="ButtonText"
          to={`/${project.name.replace(/ /g, "-")}/Home`}
        >
          {`${project.name} (${project.description})`}
        </StyledLink>
        <StyledLink
          last="true"
          underline="hover"
          color="ButtonText"
          to={`/${project.name.replace(/ /g, "-")}/Status`}
        >
          Status
        </StyledLink>
      </StyledBreadcrumbs>
      <StyledContentContainer>
        <StatusContent />
      </StyledContentContainer>
    </>
  );
};
const StyledContentContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledProjectName = styled.span`
  font-size: 6vw;
  color: #009dcb;
  font-weight: 700;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: rgba(0, 0, 0, 0.6);
  color: ${(props) => (props.last === "true" ? "black" : "rgba(0,0,0,0.6)")};
  &:hover {
    text-decoration: underline;
  }
`;

const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin: 10px;
`;
export default Status;
