import React from "react";
import { useGetDashboardPerformance } from "../../../../hooks/hooks";
import configData from "../../../../config.js";
import styled from "@emotion/styled";
import { Box, CircularProgress, Typography } from "@mui/material";
import {
  ComposedChart,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { Link } from "react-router-dom";

const PerformancePaper = ({ project }) => {
  const { data } = useGetDashboardPerformance({
    endpoint: `${configData.SERVER_URL}dashboard/operational/${project.id}`,
    refetchInterval: 60000,
    requestId: `${project.id}-dashboard-operational`,
    parameters: { daysago: 0 },
  });
  const graphData = data?.pages[0] ? data.pages[0].data.results : null;

  return (
    <StyledLink to={`/${project.name.replace(/ /g, "-")}/Operational`}>
      <StyledContainer
        sx={{
          background: "#000",
          color: "white",
          padding: "30px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <>
          <div style={{ textAlign: "center" }}>
            <Typography variant="h5">Activity:</Typography>
          </div>
          {graphData ? (
            <ChartContainer
              style={{
                position: "relative",
                width: "150px",
                height: "80px",
                marginLeft: "40px",
              }}
            >
              <ResponsiveContainer>
                <ComposedChart data={graphData}>
                  <XAxis type="category" dataKey="dateFrom" hide={true} />
                  <YAxis
                    label={{
                      value: "amount/period",
                      angle: 0,
                      position: "insideTopLeft", // align label to the top left of the axis
                      dy: -25, // adjust the distance between the label and the axis
                    }}
                    tickLine={false} // remove tick lines
                    dataKey="amount"
                    stroke="#fff"
                    orientation="left"
                    yAxisId={1}
                    hide={true}
                  />

                  <Line
                    strokeWidth={3}
                    type="monotone"
                    stroke="white"
                    dataKey="amount"
                    dot={false}
                    yAxisId={1}
                    stackId="1"
                  />
                </ComposedChart>
              </ResponsiveContainer>
            </ChartContainer>
          ) : (
            <CircularProgress />
          )}
        </>
      </StyledContainer>
    </StyledLink>
  );
};
const ChartContainer = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const StyledLink = styled(Link)`
  text-decoration: none;
`;
const StyledContainer = styled(Box)`
  box-shadow: 1px 8px 43px -2px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 1px 8px 43px -2px rgba(0, 0, 0, 0.5);
  height:100%;
  display:flex;
  align-items:center;
  justify-content:center;
`;

export default PerformancePaper;
