import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AutoGraph,
  Dashboard,
  Engineering,
  Folder,
  HealthAndSafety,
  History,
  Home,
  Info,
  Logout,
  Memory,
  Outbound,
  Person,
  QrCode,
  ShowChart,
  TableChart,
} from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";

export default function SideMenu({ handleLogout, username }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const location = useLocation();
  const pages = useSelector((state) => state.pages);
  const project = useSelector((state) => state.project);

  const toggleDrawer = () => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsOpen((prev) => !prev);
  };

  const assignIcon = (page) => {
    if (page.name === "Scanners") {
      return <QrCode />;
    } else if (page.name === "Home") {
      return <Home />;
    } else if (page.name === "Dashboard") {
      return <Dashboard />;
    } else if (page.name === "History") {
      return <History />;
    } else if (page.name === "Logs") {
      return <TableChart />;
    } else if (page.name === "Status") {
      return <HealthAndSafety />;
    } else if (page.name === "Users") {
      return <Person />;
    } else if (page.name === "Metrics") {
      return <ShowChart />;
    } else if (page.name === "Workstations") {
      return <Engineering />;
    } else if (page.name === "Chutes") {
      return <Outbound />;
    } else if (page.name === "Operational") {
      return <AutoGraph />;
    }
    else if (page.name === "PLC logs") {
      return <Memory />;
    }
    else if (page.name === "PLC messages") {
      return <Memory />;
    }
  };

  const list = () => (
    <StyledBox
      role="presentation"
      onClick={toggleDrawer()}
      onKeyDown={toggleDrawer()}
    >
      {project ? (
        <Box
          sx={{
            my: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: 700,
          }}
        >
          <span>{project.description}</span>
        </Box>
      ) : null}
      {project ? (
        <List>
          {pages
            .filter((page) => {
              if (username.includes("ARCO")) {
                return page.name !== "Status";
              }
              else{
                return page
              }
            })
            .map((page, index) =>
              location.pathname ===
              `/${project.name.replace(/ /g, "-")}/${page.name.replace(
                / /g,
                "-"
              )}` ? (
                <ActiveLink
                  key={page.name}
                  style={{ textDecoration: "none", color: "black" }}
                  to={`/${project.name.replace(/ /g, "-")}/${page.name.replace(
                    / /g,
                    "-"
                  )}`}
                >
                  <StyledListItem
                    sx={{ background: "#508C9E" }}
                    key={page.id}
                    disablePadding
                  >
                    <ListItemButton>
                      <ListItemIcon sx={{ color: "#f1f1f1" }}>
                        {assignIcon(page)}
                      </ListItemIcon>
                      <ListItemText
                        sx={{ color: "#f1f1f1" }}
                        primary={page.name}
                      />
                    </ListItemButton>
                  </StyledListItem>
                </ActiveLink>
              ) : (
                <Link
                  key={page.name}
                  style={{ color: "black", textDecoration: "none" }}
                  to={`/${project.name.replace(/ /g, "-")}/${page.name.replace(
                    / /g,
                    "-"
                  )}`}
                >
                  <ListItem key={page.id} disablePadding>
                    <ListItemButton>
                      <ListItemIcon sx={{ color: "black" }}>
                        {assignIcon(page)}
                      </ListItemIcon>
                      <ListItemText primary={page.name} />
                    </ListItemButton>
                  </ListItem>
                </Link>
              )
            )}
        </List>
      ) : null}
      <Divider sx={{ background: "black" }} />
      <List>
        {location.pathname === "/" ? (
          <Link style={{ color: "black", textDecoration: "none" }} to="/">
            <StyledListItem sx={{ background: "#508C9E" }} disablePadding>
              <ListItemButton>
                <ListItemIcon sx={{ color: "#f1f1f1" }}>
                  <Folder sx={{ color: "#f1f1f1" }} />
                </ListItemIcon>
                <ListItemText sx={{ color: "#f1f1f1" }} primary={"Projects"} />
              </ListItemButton>
            </StyledListItem>
          </Link>
        ) : (
          <Link style={{ color: "black", textDecoration: "none" }} to="/">
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <Folder sx={{ color: "black" }} />
                </ListItemIcon>
                <ListItemText primary={"Projects"} />
              </ListItemButton>
            </ListItem>
          </Link>
        )}

          <Link style={{ color: "black", textDecoration: "none" }} to="/TermsAndConditions">
        <ListItem
          disablePadding
          >
          <ListItemButton>
            <ListItemIcon>
              <Info sx={{ color: "black" }} />
            </ListItemIcon>
            <ListItemText primary={"Terms and Conditions"} />
          </ListItemButton>
        </ListItem>
        </Link>
        <ListItem
          onClick={(event) => {
            event.stopPropagation();
            handleLogout();
          }}
          disablePadding
        >
          <ListItemButton>
            <ListItemIcon>
              <Logout sx={{ color: "black" }} />
            </ListItemIcon>
            <ListItemText primary={"Logout"} />
          </ListItemButton>
        </ListItem>
      </List>
    </StyledBox>
  );

  return (
    <div>
      <React.Fragment>
        <IconButton
          onClick={toggleDrawer()}
          size="large"
          edge="start"
          color="inherit"
          aria-label="open drawer"
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton>
        <SwipeableDrawer
          anchor={"left"}
          open={isOpen}
          onClose={toggleDrawer()}
          onOpen={toggleDrawer()}
        >
          {list()}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
const StyledListItem = styled(ListItem)`
  textdecoration: none;
  backgroundcolor: #508c9e !important;
  color: black;
`;

const StyledBox = styled(Box)`
  background: #f0f0f0;
  min-width: 300px;
  height: 100vh;
  color: black;
`;

const ActiveLink = styled(Link)`
  background-color: #222628;
`;
