import React, { useCallback, useRef, useState } from "react";
import Table from "./Table";
import { LightTooltip } from "../../Shared";
import moment from "moment";
import TableControls from "./TableControls";

const TableContainer = () => {
  const gridRef = useRef();

  const [columns, setColumns] = useState({
    objectId: true,
    category: true,
    timestamp: true,
    subCategory: true,
    errorMessages: true,
  });

  const toggleColumn = useCallback((column) => {
    setColumns((prev) => ({
      ...prev,
      [column]: !prev[column],
    }));
  }, []);

  const columnDefs = [
    {
      field: "objectId",

      hide: !columns.objectId,
    },
    {
      field: "category",
      hide: !columns.category,
    },
    {
      field: "timestamp",
      hide: !columns.timestamp,
      valueGetter: ({data})=>{
        return moment(data.timeStamp).format("YYYY/MM/DD HH:mm:ss.SSS")
      },
    },
    { field: "subCategory", hide: !columns.subCategory },
    { field: "errorMessage", hide: !columns.errorMessages },
  ];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <TableControls
        gridRef={gridRef}
        columns={columns}
        toggleColumn={toggleColumn}
      />
      <Table columnDefs={columnDefs} />
    </div>
  );
};

export default TableContainer;
