import { createTheme } from "@mui/material/styles";

const lightTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#ff4081", // change the color to your preferred secondary color for dark theme
    },
    background: {
      default: "#f1f1f1",
      alt: "#ffffff",
      blue: "#1976d2",
      secondary: "#757575", // change the color to your preferred background color for dark theme
    },
    button: {
      default: "#1976d2",
      disabled: "#757575",
    },
    text: {
      primary: "#000000",
      secondary: "#757575",
      light: "#ffffff", // change the color to your preferred text color for dark theme
    },
    stroke: {
      primary: "#757575",
    },
    error: {
      main: "#e57373", // change the color to your preferred error color for dark theme
    },
  },
});

const darkTheme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#64b5f6", // change the color to your preferred primary color for dark theme
    },
    secondary: {
      main: "#ff4081", // change the color to your preferred secondary color for dark theme
    },
    background: {
      default: "#212121", // change the color to your preferred background color for dark theme
    },
    text: {
      primary: "#f5f5f5", // change the color to your preferred text color for dark theme
    },
    error: {
      main: "#e57373", // change the color to your preferred error color for dark theme
    },
  },
});

export { lightTheme, darkTheme };
