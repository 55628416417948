import { Button, Checkbox, FormControl, MenuItem, Select } from "@mui/material";
import React from "react";
import { ControlsContainer, StyledSelectTitle } from ".";
import { Refresh } from "@mui/icons-material";
import { DatePicker } from "antd";
import moment from "moment";
import { useSelector } from "react-redux";

const TableControls = ({
  data,
  gridRef,
  columns,
  toggleColumn,
  refetch,
  handleModifyAuxId,
  remove,
  handleDateChange,
  hasDateFilter,
}) => {

  const {firstDate} = useSelector((state) => state.project);
const disabledDate = (current) => {
  
  return current && (current > moment().endOf("day") || current < moment(firstDate));
};

  return (
    <ControlsContainer sx={{ minHeight: "60px" }} className="example-header">
      <div>
        <Button
        variant="ghost"
        onClick={() => {
          handleModifyAuxId(null);
          remove();
        }}
      >
        <Refresh />
        
      </Button>
        <StyledSelectTitle>Rows per page :</StyledSelectTitle>
        <Select
          sx={{ backgroundColor: "#ffffff", margin: "0 10px", height: "30px" }}
          defaultValue={1000}
          id="page-size"
          label="Page Size"
          onChange={(event) => {
            const pageSize = event.target.value;
            gridRef.current.api.paginationSetPageSize(Number(pageSize));
          }}
        >
         <MenuItem value={1000}>1000</MenuItem>
          <MenuItem value={10000}>10000</MenuItem>
          <MenuItem value={50000}>50000</MenuItem>
          <MenuItem value={100000}>100000</MenuItem>
        </Select>
      </div>
     {hasDateFilter&& <DatePicker
     style={{zIndex:1000}}
     disabled={!data} 
      onChange={handleDateChange}
      allowClear={false}
      disabledDate={disabledDate}
      />}
      <div>
        <StyledSelectTitle>Columns:</StyledSelectTitle>

        <FormControl
          sx={{ background: "#fff", margin: "0 10px" }}
          className="column-select"
        >
          <Select
            sx={{ width: "200px", height: "30px" }}
            multiple
            value={Object.keys(columns).filter((key) => columns[key])}
            onChange={(event, obj) => {
              const columnName = obj.props.value;
              toggleColumn(columnName);
            }}
            renderValue={(selected) => selected.join(", ")}
          >
            {Object.keys(columns).map((column) => (
              <MenuItem key={column} value={column}>
                <Checkbox checked={columns[column]} />
                {column}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </ControlsContainer>
  );
};

export default TableControls;
