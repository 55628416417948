import styled from "@emotion/styled";
import { Circle, Warning } from "@mui/icons-material";
import { Box, Button, Card, Tooltip, Typography } from "@mui/material";
import React from "react";
import { bindActionCreators } from "redux";
import { projectActionsCreators } from "../../state";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

const ProjectCard = ({ project }) => {
  const { error, totalError, image, description, connectionStatus } = project;
  const dispatch = useDispatch();

  const { setProject } = bindActionCreators(projectActionsCreators, dispatch);
  const handleAccess = () => {
    setProject(project);
    sessionStorage.setItem("project", JSON.stringify(project));
  };
  return (
    <Card
      sx={{
        height: "300px",
        margin: "10px",
        width: "300px",
        padding: "30px",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{ display: "flex", justifyContent: "space-between", width: "100%", paddingBottom:"5px" }}
      >
        <Typography fontWeight={600}>{project.name}</Typography>
        {/* {!isLite && (error ? (
          <Tooltip placement="top" title={"error"}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Circle sx={{ color: "red" }} />
              <Typography>
                {totalError > 1
                  ? `${totalError} errors`
                  : `${totalError} error`}
              </Typography>
            </Box>
          </Tooltip>
        ) : (
          
        ))} */}
        <Box sx={{ display: "flex", alignItems: "center",  }}>
          <Circle
            sx={{
              color:
                connectionStatus === "Synced"
                  ? "green"
                  : connectionStatus === "Syncing..."
                  ? "orange"
                  : "red",
                  width:"15px",
                  marginX:"5px"
            }}
          />
          <Typography>{connectionStatus}</Typography>
        </Box>
      </Box>
      <Typography>{description}</Typography>
      <ResponsiveImage src={image} />
      <StyledLink to={`/${project.name.replace(/ /g, "-")}`}>
        <Button
          onClick={handleAccess}
          sx={{
            background: `${error ? "red" : "#508C9E"}`,
            display: "flex",
            alignItems: "center",
            "&:hover": {
              backgroundColor: `${error && "red"}`,
            },
          }}
          variant="contained"
        >
          {error && <Warning sx={{ marginX: "5px" }} />}access project
        </Button>
      </StyledLink>
    </Card>
  );
};
const StyledLink = styled(Link)`
  text-decoration: none;
`;

const ResponsiveImage = styled.img`
  max-width: 100%;
  height: auto;
  object-fit: contain;
  flex: 1;
`;
export default ProjectCard;
