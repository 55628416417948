import * as React from "react";
import Draggable from "react-draggable";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import SaveIcon from "@mui/icons-material/Save";
import { Image } from "@mui/icons-material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
import LayoutDialog from "./LayoutDialog";

export default function ActionsButton() {
  const [open, setOpen] = React.useState(false);
  const [isLayoutOpen, setLayoutOpen] = useState(false);
  const handleClick = () => {
    setOpen((prev) => !open);
  };

  const [actions, setActions] = useState([]);

  const project = useSelector((state) => state.project);
  const tableData = useSelector((state) => state.tableData);

  function downloadCSV(data) {
    const headers = Object.keys(data[0]);
    const csvContent =
      "data:text/csv;charset=utf-8," +
      headers.map((header) => `${header}`).join(",") +
      "\n" +
      data.map((row) => Object.values(row).join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${project.name} data.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }


  useEffect(() => {
    // if (tableData.length > 0) {
    //   setActions([
    //     { icon: <Image />, name: "Layout", function: handleLayoutOpen },
    //     {
    //       icon: <SaveIcon />,
    //       name: "CSV",
    //       function: () => downloadCSV(tableData),
    //     },
    //   ]);
    // } else
     if (project) {
      setActions([
        { icon: <Image />, name: "Layout", function: handleLayoutOpen },
      ]);
    } else {
      setActions([]);
    }
  }, [project, tableData]);

  const handleLayoutOpen = () => {
    setLayoutOpen(true);
  };

  const handleLayoutClose = () => {
    setLayoutOpen(false);
  };


  return (
    <>

      <Draggable    
        bounds="parent"
        disabled={true}
        >
            
        <Box
        
          sx={{
            height: "100vh",
            zIndex: 10000,
            position: "absolute",
            bottom: 36,
            right: 16,
            transform: "translateZ(0px)",
            flexGrow: 1,
          }}
          >

          <Backdrop open={open} />
          <SpeedDial
            className="speedDial"
            hidden={!project}
            ariaLabel="SpeedDial tooltip example"
            sx={{ position: "absolute", bottom: 16, right: 16 }}
            color="#508C9E"
            FabProps={{
              sx: {
                bgcolor: "#508C9E",
                "&:hover": {
                  bgcolor: "#508C9E",
                },
              },
            }}
            icon={<SpeedDialIcon className="handle" />}
            onClick={handleClick}
            open={open}
          >
            {actions.map((action) => (
              <SpeedDialAction
              key={action.name}
                icon={action.icon}
                className="action"
                tooltipTitle={action.name}
                tooltipOpen
                onClick={(e) => {
                  e.stopPropagation();
                  action.function();
                  handleClick(); // Close the SpeedDial after clicking an action
                }}
                />
                ))}
          </SpeedDial>
  
        </Box>
      </Draggable>
      {isLayoutOpen ? (
        <LayoutDialog handleClose={handleLayoutClose} open={isLayoutOpen} />
        ) : null}
    </>
  );
}
