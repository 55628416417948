import React, { Suspense, lazy, useState } from "react";
import configData from "../../../config.js";
import { useSelector } from "react-redux";
import { Box, CircularProgress, Typography, Switch, Paper } from "@mui/material";
import {  useGetMetrics } from "../../../hooks/hooks.jsx";
import styled from "@emotion/styled";
import { DatePicker } from "antd";
import moment, { now } from "moment";
const Chart = lazy(() => import("./Chart.jsx"));

const MetricsContent = () => {
  const project = useSelector((state) => state.project);
  const [realtime, setRealtime] = useState(false);
  const [selectedDate,setSelectedDate] = useState(moment(now()).format("YYYY-MM-DD Z"))
  const { data,refetch,remove } = useGetMetrics({
    endpoint: `${configData.SERVER_URL}metrics/${project.id}`,
    refetchInterval: realtime ? 15000 : false,
    requestId: `${project.id}-metrics`,
    parameters: {selectedDate },
  });

  const handleRealtimeToggle = () => {
    if(!realtime){
      refetch()
    }
    setRealtime(!realtime);
  };

  const handleDateChange = (_,date) => {
    remove();
    if (date === null) {
      setSelectedDate(moment(now()).format("YYYY-MM-DD Z"));
    } else {
      setSelectedDate(moment(date).format("YYYY-MM-DD Z"));
    }
  };
  const {firstDate} = useSelector((state) => state.project);
  const disabledDate = (current) => {
  
    return current && (current > moment().endOf("day") || current < moment(firstDate));
  };
  return (
    <>
           <Box sx={{ display: "flex", alignItems: "center", justifyContent:"space-between", marginBottom: "10px" }}>
        <div style={{ display: "flex", alignItems: "center"}}>
        <Switch
          disabled={!data || data.length < 1}
          checked={realtime}
          onChange={handleRealtimeToggle}
          />
        <Typography sx={{ marginLeft: "10px" }}>Automatic updates</Typography>
          </div>
          <DatePicker
     disabled={!data || realtime} 
      onChange={handleDateChange}
      allowClear={false}
      disabledDate={disabledDate}
      />
      <div></div>

      </Box>
      <Box
        sx={{
          height: "100%",
          overflow: "auto",
          display: "flex",
          flexWrap: "wrap",
          padding: "2%",
          justifyContent: "center",
        }}
      >
        {data && data.length > 0 ? (
          data.map((chart) => (              
          <Suspense
            fallback={
              <ChartContainer
                key={chart.objectId + chart.property}
                style={{
                  position: "relative",
                  width: "670px",
                  height: "450px",
                }}
              >
                <strong>{chart.objectId}</strong>
                <span>{chart.property}</span>
                Loading...
              </ChartContainer>
            }
          >
            <Chart data={chart} />
            </Suspense>))
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
           {data?.length!==0 && <CircularProgress  />}
            <Typography sx={{ marginX: "5px" }}>{data?.length===0 ? 'No data (once the data is available the page will update automatically)': <Loader class="ag-overlay-loading-center">Please wait while requesting data</Loader>}</Typography>
          </Box>
        )}
      </Box>
    </>
  );
};
const ChartContainer = styled(Paper)`
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Loader = styled.span`
background-color:#F1F1F1;
color:#3E4244;
padding:6px;
border: solid 1px #3E4244;
font-size:14px;
box-shadow: 10px -1px 75px 10px rgba(0,0,0,0.27);
-webkit-box-shadow: 10px -1px 75px 10px rgba(0,0,0,0.27);
-moz-box-shadow: 10px -1px 75px 10px rgba(0,0,0,0.27);
`
export default MetricsContent;
