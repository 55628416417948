import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Zoom } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import configData from "../../config.js";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { useGetLayouts } from "../../hooks/hooks.jsx";

export default function LayoutDialog({ handleClose, open }) {
  const project = useSelector((state) => state.project);

  const { data } = useGetLayouts({
    endpoint: `${configData.SERVER_URL}images/${project.id}`,
    requestId: `${project.id}-Layouts`,
  });

  return (
    <Dialog fullWidth maxWidth="xl" open={open} onClose={handleClose}>
      <DialogTitle>{`${project.name} (${project.description})`} Layouts</DialogTitle>
      <DialogContent>
        {data ? (
          data.length > 0 ? (
            <Swiper
              style={{ height: "50vh" }}
              pagination={{
                type: "progressbar",
              }}
              navigation={true}
              modules={[Pagination, Navigation, Zoom]}
              className="mySwiper"
            >
              {data.map((img) => (
                <React.Fragment key={img.id}>
                  <p>{img.name}</p>
                  <SwiperSlide
                    key={img.id}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{ maxWidth: "80%", maxHeight: "100%" }}
                      alt={img.name}
                      src={img.content}
                    />
                  </SwiperSlide>
                </React.Fragment>
              ))}
            </Swiper>
          ) : (
            <Box
              sx={{
                height: "50vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h1>No Layouts for this project</h1>
            </Box>
          )
        ) : (
          <Box
            sx={{
              height: "50vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h1>Getting Images...</h1>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button sx={{color:'#508C9E'}} onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
