import styled from "@emotion/styled";
import { Breadcrumbs, Paper } from "@mui/material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HistoryContent from "./HistoryContent.jsx";
import { bindActionCreators } from "redux";
import { tableDataActionsCreators } from "../../../state/index.js";
const History = () => {
  const project = useSelector((state) => state.project);
  const dispatch = useDispatch();
  const { clearTableData } = bindActionCreators(
    tableDataActionsCreators,
    dispatch
  );

  useEffect(() => {
    return () => {
      clearTableData();
    };
  }, []);

  return (
    <>
      <StyledBreadcrumbs maxItems={3} aria-label="breadcrumb">
        <StyledLink underline="hover" color="inherit" to="/">
          Projects
        </StyledLink>
        <StyledLink
          underline="hover"
          color="ButtonText"
          to={`/${project.name.replace(/ /g, "-")}/Home`}
        >
         {`${project.name} (${project.description})`}
        </StyledLink>
        <StyledLink
          last="true"
          underline="hover"
          color="ButtonText"
          to={`/${project.name.replace(/ /g, "-")}/History`}
        >
          History
        </StyledLink>
      </StyledBreadcrumbs>
      <StyledContentContainer>
        <HistoryContent />
      </StyledContentContainer>
    </>
  );
};
const StyledContentContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: rgba(0, 0, 0, 0.6);
  color: ${(props) => (props.last === "true" ? "black" : "rgba(0,0,0,0.6)")};
  &:hover {
    text-decoration: underline;
  }
`;

const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin: 10px;
`;
export default History;
