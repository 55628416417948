import { combineReducers } from "redux";
import pagesReducer from "./pagesReducer";
import projectReducer from "./projectReducer";
import projectsReducer from "./projectsReducer";
import tableDataReducer from "./tableDataReducer";

const reducers = combineReducers({
  project: projectReducer,
  pages: pagesReducer,
  projects: projectsReducer,
  tableData: tableDataReducer,
});

export default reducers;
