import { AgGridReact } from "ag-grid-react";
import React, { useMemo } from "react";
import { LightTooltip } from ".";
import "ag-grid-community/dist/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/dist/styles/ag-theme-alpine.css"; // Optional theme CSS
import { toast } from "react-hot-toast";

const Table = ({
  gridRef,
  columnDefs,
  data,
  fetchNextPage,
  pageSize,
  totalRows,
  isLoading,
  firstDate,
  hasFirstDateColumn,
}) => {
  const defaultColDef = useMemo(() => {
    return {
      minWidth: 50,
      floatingFilter: true,
      resizable: true,
      filter: true,
      editable: true,
      sortable: true,
      flex: 1,
      cellRenderer: (data) => {
        return (
          <LightTooltip title={`${data.value}`}>
            <span>{`${data.value}`}</span>
          </LightTooltip>
        );
      },
    };
  }, []);

  const firstDateColumn = {
    field: "data from",
    suppressSizeToFit: true,
    valueGetter: () => {
      return firstDate;
    },
  };

  const handlePagination = async (event) => {
    const currentPage = event.api.paginationGetCurrentPage() + 1;
    const totalPages = event.api.paginationGetTotalPages();
    if (
      currentPage === totalPages &&
      event.api.getModel().getRowCount() !== totalRows &&
      !event.api.isAnyFilterPresent()
    ) {
      // event.api.showLoadingOverlay();

      const toastId = toast.loading("Getting new data");
      await fetchNextPage();
      toast.success(
        `got new data ${
          event.api.getModel().getRowCount() + pageSize > totalRows
            ? totalRows
            : event.api.getModel().getRowCount() + pageSize
        } / ${totalRows}`,
        { id: toastId }
      );
    }
  };

  return (
    <div className="grid-wrapper ag-theme-alpine" style={{ flex: 1 }}>
      <AgGridReact
        ref={gridRef}
        rowData={data ? data : null}
        columnDefs={
          hasFirstDateColumn ? [firstDateColumn, ...columnDefs] : columnDefs
        }
        defaultColDef={defaultColDef}
        pagination={true}
        rowHeight={30}
        paginationPageSize={1000}
        suppressDragLeaveHidesColumns={true}
        onPaginationChanged={(event) => {
          handlePagination(event);
        }}
        overlayLoadingTemplate={
          !data &&
          '<span class="ag-overlay-loading-center">Please wait while requesting data</span>'
        }
        overlayNoRowsTemplate={
          !data && isLoading
            ? '<span class="ag-overlay-loading-center">Please wait while requesting data</span>'
            : "no data Available"
        }
      />
    </div>
  );
};

export default Table;
