import React from "react";
import { useGetData } from "../../../../hooks/hooks";
import configData from "../../../../config.js";
import styled from "@emotion/styled";
import { Divider, Paper, Skeleton, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import moment from "moment";

const HistoryPaper = ({ project }) => {
  const { data } = useGetData({
    endpoint: `${configData.SERVER_URL}History/${project.id}`,
    parameters: { pageSize: 30 },
    requestId: `dashboard-${project.id}-history`,
    refetchInterval: 60000,
  });

  function limitText(text, maxLength) {
    if (text.length <= maxLength) {
      return text;
    } else {
      return text.slice(0, maxLength) + "...";
    }
  }
  return (
    <div  >
        <Typography variant="h6">Last time changed: {data?.pages[0] ? moment(data.pages[0].data.results[0].createdAt).format('DD/MM/YYYY HH:mm:ss'):' loading...'}</Typography>
    <StyledLink to={`/${project.name.replace(/ /g, "-")}/History`}>
      <StyledContainer >
        <Typography variant="h4">Recent activity:</Typography>
        {data?.pages[0] ? (
          data.pages[0].data.results.map((history) => (
            <StyledLine key={`${history.objectId}: ${history.changes} ${Math.random()}`}>
              <Divider color="#fff" />
              <Typography fontWeight={500} color={'#ededed'} variant="h6">
                {limitText(history.objectId, 30)}
                {": "}
                {limitText(history.changes, 30)}
              </Typography>
            </StyledLine>
          ))
        ) : (
          <>
      
            <div className="">
              <Skeleton variant="rounded" width={35} height={35} />
              <Skeleton variant="text" width={90} height={35} />
            </div>
            <div className="">
              <Skeleton variant="rounded" width={35} height={35} />
              <Skeleton variant="text" width={90} height={35} />
            </div>
            <div className="">
              <Skeleton variant="rounded" width={35} height={35} />
              <Skeleton variant="text" width={90} height={35} />
            </div>
            <div className="">
              <Skeleton variant="rounded" width={35} height={35} />
              <Skeleton variant="text" width={90} height={35} />
            </div>
            <div className="">
              <Skeleton variant="rounded" width={35} height={35} />
              <Skeleton variant="text" width={90} height={35} />
            </div>
          </>
        )}
      </StyledContainer>
    </StyledLink>
          </div>
  );
};

const StyledContainer = styled(Paper)`
  background:  #508C9E;
  color: white;
  padding: 10px;
  box-shadow: 1px 8px 43px -2px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 1px 8px 43px -2px rgba(0, 0, 0, 0.5);
`;
const StyledLine = styled.div`
  padding: 10px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

export default HistoryPaper;
