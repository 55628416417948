import styled from "@emotion/styled";
import { Paper, Tooltip, tooltipClasses } from "@mui/material";

export const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: 15,
    border: "1px solid black",
  },
}));
export const StyledSelectTitle = styled.span`
  @media screen and (max-width: 570px) {
    display: none;
  }
`;
export const ControlsContainer = styled(Paper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  background: #ffffff;
  color: #181d1f;
  border: 1px solid rgb(186, 191, 199);
  border-bottom: 0px;
  border-radius: 0px;
`;
