export const setTableData = (pages) => {
  return (dispatch) => {
    dispatch({
      type: "setTableData",
      payload: pages,
    });
  };
};

export const clearTableData = (pages) => {
  return (dispatch) => {
    dispatch({
      type: "clearTableData",
      payload: [],
    });
  };
};
