import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import configData from "../../../config.js";

import moment from "moment/moment.js";
import { LightTooltip } from "../../Shared/index.js";
import TableWithControls from "../../Shared/TableWithControls.jsx";
import { bindActionCreators } from "redux";
import { tableDataActionsCreators } from "../../../state/index.js";

const WorkstationsContent = () => {
  const gridRef = useRef();
  const project = useSelector((state) => state.project);

  const dispatch = useDispatch();
  const { setTableData } = bindActionCreators(
    tableDataActionsCreators,
    dispatch
  );
  useEffect(() => {
    return () => {
      setTableData([]);
    };
  }, [setTableData]);

  const toggleColumn = useCallback((column) => {
    setColumns((prevColumns) => ({
      ...prevColumns,
      [column]: !prevColumns[column],
    }));
  }, []);

  const [columns, setColumns] = useState({
    name: true,
    enabled: true,
    workload: true,
    types: true,
  });

  const columnDefs = useMemo(
    () => [
      {
        field: "name",
        hide: !columns.name,
      },
      {
        field: "enabled",
        suppressSizeToFit: true,
        hide: !columns.enabled,
      },
      {
        field: "workload",
        hide: !columns.workload,
        suppressSizeToFit: true,
      },
      {
        field: "types",
        hide: !columns.types,
        suppressSizeToFit: true,
      },
    ],
    [columns]
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <TableWithControls
        toggleColumn={toggleColumn}
        gridRef={gridRef}
        columns={columns}
        columnDefs={columnDefs}
        endpoint={`${configData.SERVER_URL}stations/Workstation/${project.id}`}
        requestId={`${project.id}-Workstations`}
      />
    </div>
  );
};

export default WorkstationsContent;
