import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Input, Alert } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import ProjectCard from "./ProjectCard.jsx";
import configData from "../../config.js";
import { Search } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import {
  pagesActionsCreators,
  projectActionsCreators,
  projectsActionsCreators,
} from "../../state/index.js";

const ProjectsContent = () => {
  const [filter, setFilter] = React.useState("");
  const dispatch = useDispatch();
  const { clearProject } = bindActionCreators(projectActionsCreators, dispatch);
  const { clearProjects } = bindActionCreators(
    projectsActionsCreators,
    dispatch
  );

  const { clearPages } = bindActionCreators(pagesActionsCreators, dispatch);

  // Fetch projects function using Axios
  const fetchProjects = async () => {
    try {
      const response = await axios.get(`${configData.SERVER_URL}Projects`);
      return response.data;
    } catch (error) {
      // Pass the error to React Query
      throw error;
    }
  };

  // Use React Query for polling
  const {
    data: projects,
    isLoading,
    error,
  } = useQuery(["projects"], fetchProjects, {
    refetchInterval: 5000, // Poll every 5000ms (5 seconds)
  });

  // Handle filter change
  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  useEffect(() => {
    clearProject();
    clearPages();
    clearProjects();
  }, []);

  // Filter projects
  const filteredProjects = projects?.results?.filter(
    (project) =>
      project.name.toLowerCase().includes(filter.toLowerCase()) ||
      project.description.toLowerCase().includes(filter.toLowerCase())
  );

  // Loading state
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <CircularProgress size={80} variant="indeterminate" color="primary" />
      </Box>
    );
  }

  // Error state
  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Alert severity="error">Error fetching projects: {error.message}</Alert>
      </Box>
    );
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Input
          sx={{ margin: "10px" }}
          type="text"
          placeholder="project name or descri..."
          value={filter}
          onChange={handleFilterChange}
        />
        <Search />
      </div>
      <Box
        sx={{
          height: "100%",
          overflow: "auto",
          display: "flex",
          flexWrap: "wrap",
          padding: "2%",
          justifyContent: "center",
        }}
      >
        {filteredProjects?.length > 0 ? (
          filteredProjects.map((project) => (
            <ProjectCard key={project.id} project={project} />
          ))
        ) : (
          <p>No projects found</p>
        )}
      </Box>
    </>
  );
};

export default ProjectsContent;
